
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import {slab} from "@/core/data/genericData";
import {active} from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  directives: {mask},
  components: {
    AddTagsModal
    },

  setup() {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductHsnModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const slab_list_data = slab;
    const formData = ref({
      hsn: "",      
      chapter: "",
      heading: "",
      description: "",
      slab0: "",
      slab5: "",
      slab12: "",
      slab18: "",
      slab28: "",
    });    

    const rules = ref({
      hsn: [{
          required: true,
          message: 'Please enter HSN Number',
        },
        {
          required: true,
          message: "Enter 8-digit Hsn",
          trigger: "change",  
          pattern: /[0-9]/,
          min: 8,
          max: 8,        
        },        
      ],    
      chapter: [
        {
          required: true,
          message: "Enter 2-digit Chapter",
          trigger: "change",  
          pattern: /[0-9]/,
          min: 2,
          max: 2,        
        },        
      ],    
      heading: [
        {
          required: true,
          message: "Enter 4-digit Heading",
          trigger: "change",  
          pattern: /[0-9]/, 
          min: 4,
          max: 4,        
        },        
      ],  
      // description: [
      //   {
      //     required: true,
      //     message: "Enter Description",
      //     trigger: "change",  
                
      //   },        
      // ],   
      slab0: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab5: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab12: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab18: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
      slab28: [
        {
          required: true,
          message: "Please select",
          trigger: "change",  
                
        },        
      ],    
    });

    const setHsnData = async (data) => {
      const taxslab = {
        "0" : Number(data.slab0),
        "5" : Number(data.slab5),
        "12" : Number(data.slab12),
        "18" : Number(data.slab18),
        "28" : Number(data.slab28),
      }
    
      const db_data = {
        "hsn_code": Number(data.hsn),
        "hsn_chapter": Number(data.chapter),
        "hsn_heading": Number(data.heading),
        "hsn_description": data.description,
        "tax_slab": taxslab,
        "active": 1,
        "cess_details": {},
        }

        await store.dispatch(Actions.CUST_ADD_HSN, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Hsn has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductHsnModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }
   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setHsnData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    
    onMounted( async () => {
     // await setMakingdata(making.value)
      //await setCompositiondata(comp.value)
      //await setShapedata(shape.value)
    });
    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addProductHsnModalRef,
      slab_list_data,
      active_list_data
    };
  },
});
